
import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '@/components/Home/Home.vue'
import store from '@/store/index'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
		redirect: '/dashboard',
		children: [
			{

				path: '/dashboard',
				name: 'dashboard',
				component: ()=>import('@/views/dashboard/Dashboard.vue')
  		},
			{
				path: '/adjust',
				name: 'adjust',
        meta: {
          requireAuth: true
        },
				component: ()=>import('@/views/adjust/Adjust.vue'),
				redirect: '/adjust/balance',
				children:[
					{
						path: '/adjust/balance',
						name: 'balance',
            meta: {
              requireAuth: true
            },
						component: ()=>import('@/views/balance/Balance.vue')
					},
					{
						path: '/adjust/regulate',
						name: 'regulate',
            meta: {
              requireAuth: true
            },
						component: ()=>import('@/views/regulate/Regulate.vue')
					},
					{
						path: '/adjust/reexamine',
						name: 'reexamine',
            meta: {
              requireAuth: true
            },
						component: ()=>import('@/views/reexamine/Reexamine.vue')
					},
					{
						path: '/adjust/admission',
						name: 'admission',
            meta: {
              requireAuth: true
            },
						component: ()=>import('@/views/admission/Admission.vue')
					},
					{
						path: '/adjust/score',
						name: 'score',
            meta: {
              requireAuth: true
            },
						component: ()=>import('@/views/score/Score.vue')
					},
					{
						path: '/adjust/info',
						name: 'info',
            meta: {
              requireAuth: true
            },
						component: ()=>import('@/views/info/Info.vue')
					}
				]
			},
			{
				path: '/line-adjust',
				name: 'line-adjust',
        meta: {
          requireAuth: true
        },
				component: ()=>import('@/views/line-adjust/LineAdjust.vue'),
				redirect: '/line-adjust/plan',
				children: [
					{
						path: '/line-adjust/plan',
						name: 'plan',
            meta: {
              requireAuth: true
            },
						component: ()=>import('@/views/plan/Plan.vue')
					},
					{
						path: '/line-adjust/ask',
						name: 'ask',
            meta: {
              requireAuth: true
            },
						component: ()=>import('@/views/ask/Ask.vue')
					},
					{
						path: '/line-adjust/notify',
						name: 'notify',
            meta: {
              requireAuth: true
            },
						component: ()=>import('@/views/notify/Notify.vue')
					},
					{
						path: '/line-adjust/admission',
						name: 'lineAdmission',
            meta: {
              requireAuth: true
            },
						component: ()=>import('@/views/admission/Admission.vue')
					},
					{
						path: '/line-adjust/score',
						name: 'lineScore',
            meta: {
              requireAuth: true
            },
						component: ()=>import('@/views/score/Score.vue')
					},
          {
            path: '/line-adjust/one',
            name: 'one',
            meta: {
              requireAuth: true
            },
            component: ()=>import('@/views/one/One.vue')
          },
				]
			}
		]
	}
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to,form,next)=>{
  const isLogin = store.state.token? true : false
  if(to.meta.requireAuth){
    if(isLogin){
      next()
    } else {
      store.commit('setLoginModal',true)
      next('/')
    }
  } else {
    next()
  }
})
export default router
