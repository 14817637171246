import request from '@/utils/request'
// 验证码登录
export function login(data){
  return request({
    url: '/grad/school/encrypt/dynamic/code/login/v2',
    method: 'post',
    data
  })
}
// 获取小程序二维码
export function QRcode() {
  return request({
    url: '/app/junior/acquire/dictionary/info?type=2228',
    method: 'get'
  })
}
// 获取登录image
export function loginImage(){
  return request({
    url: '/app/junior/acquire/dictionary/info?type=8821',
    method: 'get'
  })
}
// 获取调剂模拟意向余额
export function testData(data){
	return request({
		url: '/common/simulation/system/adjust/list',
		method: 'get',
		params: {
			...data
		}
	})
}
// 获取考研身份
export function province(){
	return request({
		url: '/common/kao/yan/provinces',
		method: 'get'
	})
}
// 联系我们
export function connectUs(){
  return request({
    url: '/app/junior/acquire/dictionary/info?type=99990005'
  })
}
