
import { createStore } from 'vuex'

export default createStore({
  state: {
		token: window.localStorage.getItem('testToken') || '',
		loginModal: false,
  },
  getters: {
  },
  mutations: {
		setToken(state, token) {
			state.token = token
			window.localStorage.setItem('testToken', token)
		},
		setLoginModal(state, loginModal) {
			state.loginModal = loginModal
		}
  },
  actions: {
  },
  modules: {
  }
})
