import axios from 'axios'
import store from '@/store'
import {decrypt} from '@/utils/parseData'
import {ElMessage} from 'element-plus'
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  // baseURL: window.location.origin,
  // timeout: 9000
})
instance.interceptors.request.use(config=>{
  const token = store.state.token
  const expiredTime = window.localStorage.getItem('expiredTime')
  const timestep = (new Date().getTime()) - expiredTime
  if(timestep > 2*60*60*1000){
    window.localStorage.removeItem('testToken')
    window.localStorage.clear()
    store.commit('testToken','')
    // store.commit('setLoginModal',true)
    return config
  }
	if(token){
		config.headers.token = `${token}`
	}
  return config
})
instance.interceptors.response.use(respose=>{
  if(respose.status === 200){
		if(respose.data.status === 0){
			return respose.data
		} else {
			const result = JSON.parse(decrypt(respose.data))
      if(result.status === 8){
				// store.commit('setChance',true)
				// window.localStorage.setItem('examChance',true)
        ElMessage.error(result.msg)
			} else if(result.status !==0) {
				// ElMessage.error(result.msg)
			}
			return respose.data
		}

  }
})
export default instance
